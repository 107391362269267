import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';

import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { Box, Grid } from '@mui/material';

import iconArrowRight from '../images/donation/long_right.svg';

import ChevronRight from '../images/donation/chevron_right.svg';
import ChevronLeft from '../images/donation/chevron_left.svg';
import BackgroundImage from 'gatsby-background-image';
import SortByDropMenu from '../components/Donation/SortByDropMenu';
import { useFetch } from '../hooks';
import { AppContext } from '../apollo/wrap-root-element';
import {
  getTotalPages,
  isDateFromLastThirtyDaysDate,
  isDateLastThreeMonths,
  isDatesThisYear,
  paginatedItem,
} from '../utils/donate';
import { graphql, navigate } from 'gatsby';

import DeleteIcon from '../images/donation/delete.svg';
import RemoveRecurringDonationModal from '../components/Donation/RemoveRecurringDonationModal';
import { getListDonationProjectForSelect } from '../utils/functions';
import ComboBoxSelect from '../components/Form/ComboBox';
import { toast } from 'react-toastify';
import { Link } from 'gatsby';
import { currencySymbols } from '../modules/timeline/components/EnterButton/utils';

const RecurringDonation = (props) => {
  const {
    data: { bgGive, patreonThumb, donationsDetails, languageDonationPage },
  } = props;
  const [getCurrentUserRecurringDonation, allDonationHistory, error, loading] = useFetch('POST');
  const [
    deleteCurrentDonation,
    recurringDonationsResponse,
    errorOnDeleteRecurringDonation,
    isDeletingRecurringDonations,
  ] = useFetch('POST');

  const donationDetailsData = donationsDetails.edges[0].node.data;
  const donationProjects = donationDetailsData.projects;

  const [selectedDonatedProject, setSelectedProject] = React.useState();

  const { currentUserDetails } = React.useContext(AppContext);

  const [currentPage, setCurrentPage] = useState(1);
  const [selectedIdForRemoveRecurringDonation, setSelectedIdForRemoveRecurringDonation] = useState(-1);

  const [donationHistoryData, setDonationHistoryData] = React.useState([]);
  const pageSize = 10;

  const fetchRecurringDonations = () => {
    getCurrentUserRecurringDonation('api/donate/recurring-donations', {
      body: {
        customerId: currentUserDetails?.customer?.id,
      },
      onSuccess: (res) => {
        setDonationHistoryData(res.data);
      },
      onError: () => {},
    });
  };

  React.useEffect(() => {
    if (currentUserDetails) {
      fetchRecurringDonations();
    }
  }, [currentUserDetails]);

  const totalPage = getTotalPages(donationHistoryData.length, pageSize);
  const projectsLists = getListDonationProjectForSelect(donationProjects, languageDonationPage.edges[0].node.data.body);

  const onFilterClicked = (searchKey) => {
    if (searchKey) {
      const searchProject = projectsLists.find((item) => item.value === searchKey.value);
      const filteredData = allDonationHistory?.data?.filter((item) =>
        searchProject?.childProjectId?.toLowerCase()
          ? item?.transactionsDetailsId?.donatedProject?.subUid?.toLowerCase() ===
            searchProject?.childProjectId?.toLowerCase()
          : item?.transactionsDetailsId?.donatedProject?.uid?.toLowerCase() ===
            searchProject?.parentProjectId?.toLowerCase()
      );
      setDonationHistoryData(filteredData);
    }
  };

  const tableData = donationHistoryData ? paginatedItem(donationHistoryData, currentPage, pageSize) : [];

  const handleRemoveRecurringDonations = () => {
    if (selectedIdForRemoveRecurringDonation !== -1 && currentUserDetails) {
      deleteCurrentDonation('api/donate/disabled-recurring-donations', {
        body: {
          customerId: currentUserDetails?.customer?.id,
          recurringDonationId: selectedIdForRemoveRecurringDonation,
        },
        onSuccess: () => {
          setSelectedIdForRemoveRecurringDonation(-1);
          fetchRecurringDonations();
          toast.success('Monthly donation deleted Successfully');
        },
        onError: () => {
          toast.error('Error on Deleting Donation. Please try again later');
        },
      });
    }
  };

  return (
    <BodyClassName className=" bg-dark donation-history">
      <DefaultLayoutComponent title="Donation History">
        <BackgroundImage
          Tag="section"
          className="page-banner over_lpd-banner bgImage bg-pos-center-top"
          fluid={props?.data?.bgGive?.childImageSharp?.fluid}
          preserveStackingContext
        >
          <div className="overlay" />
        </BackgroundImage>
        <div className="site-main">
          <div className="over_lpd-container  ">
            <div className="wrapper bg-white section wrapper-xl pd-x-md">
              <div className="donation-mgmt-section">
                <RemoveRecurringDonationModal
                  open={selectedIdForRemoveRecurringDonation !== -1}
                  onClose={() => setSelectedIdForRemoveRecurringDonation(-1)}
                  onConfirmDelete={handleRemoveRecurringDonations}
                  projectName={
                    tableData?.find((item) => item._id === selectedIdForRemoveRecurringDonation)?.transactionsDetailsId
                      ?.donatedProject?.name
                  }
                  isLoading={isDeletingRecurringDonations}
                />
                <h2 className="donation-history-title">GIVING SUBSCRIPTIONS</h2>

                {allDonationHistory?.data?.length === 0 && !loading ? (
                  <>
                    <h4 style={{ textAlign: 'center', marginTop: '145px' }}>
                      No any multiple donations <br></br> has been made.
                    </h4>

                    <div style={{ marginTop: '72px', display: 'flex', justifyContent: 'center' }}>
                      <Link
                        to="/give"
                        className="btn donation-success-button  btn-md btn-primary-ii btn-submit"
                        type="button"
                      >
                        Explore PROJECTS
                      </Link>
                    </div>
                  </>
                ) : (
                  <>
                    <Grid container gap={3} justifyContent={'center'}>
                      <Grid item xs={12} md={10}>
                        <div className="search-container">
                          <ComboBoxSelect
                            options={projectsLists}
                            name="projectId"
                            buttonClass={'search-input'}
                            value={selectedDonatedProject}
                            onChange={(value) => {
                              setSelectedProject(value);

                              if (value?.label) {
                                onFilterClicked(value);
                              } else {
                                setDonationHistoryData(allDonationHistory?.data);
                              }
                            }}
                            className="w-full"
                          ></ComboBoxSelect>
                          {/* <button
                            className="btn btn-md search-button  btn-primary-li btn-tour-jumbotron "
                            onClick={onFilterClicked}>
                            <img src={iconArrowRight} />
                          </button> */}
                        </div>
                      </Grid>
                      <Grid item xs={12} md={10}>
                        <div className=" table-container ">
                          <table>
                            <thead>
                              <tr>
                                <th>SN</th>
                                <th>Start Date of Monthly Giving</th>
                                <th>Name</th>
                                <th>Amount</th>
                                {/* <th>Donation Type</th> */}
                                <th>Action</th>
                              </tr>
                            </thead>

                            {/* Table body */}
                            <tbody
                              style={{
                                textAlign: 'left',
                              }}
                            >
                              {tableData?.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{new Date(item.cycleStartDate).toDateString()}</td>
                                  <td>
                                    {item.transactionsDetailsId.donatedProject.subName ||
                                      item.transactionsDetailsId.donatedProject.name}
                                  </td>
                                  <td>
                                    {
                                      currencySymbols[
                                        item?.transactionsDetailsId?.currency
                                          ? item.transactionsDetailsId?.currency?.toUpperCase()
                                          : 'USD'
                                      ]
                                    }
                                    {item.transactionsDetailsId.amount.toFixed(2)}
                                  </td>
                                  {/* <td>{item.amount}</td> */}
                                  <td>
                                    <div style={{ display: 'flex', gap: '40px' }}>
                                      <button
                                        onClick={() => setSelectedIdForRemoveRecurringDonation(item._id)}
                                        style={{ border: 'none', backgroundColor: 'transparent' }}
                                        type="button"
                                      >
                                        <img src={DeleteIcon} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Grid>
                      {allDonationHistory?.data.length !== 0 ? (
                        <Grid item xs={12} md={10}>
                          <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                            <Grid item>
                              <div className="btn-group-pagination">
                                {currentPage !== 1 ? (
                                  <button
                                    className="btn  btn-table-pagination  btn-outline  "
                                    onClick={() => setCurrentPage(currentPage - 1)}
                                  >
                                    <img src={ChevronLeft} />
                                    <span>Previous</span>
                                  </button>
                                ) : null}

                                {currentPage < totalPage && totalPage !== 0 ? (
                                  <button
                                    className="btn  btn-table-pagination btn-outline  "
                                    onClick={() => setCurrentPage(currentPage + 1)}
                                  >
                                    <img src={ChevronRight} />
                                    <span>Next</span>
                                  </button>
                                ) : null}
                              </div>
                            </Grid>

                            <Grid item>
                              <p>Page 1 of {totalPage}</p>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </Grid>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
};

export const query = graphql`
  {
    bgGive: file(relativePath: { eq: "donate_flag.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    languageDonationPage: allPrismicLanguageDonationsPage {
      edges {
        node {
          data {
            body {
              ... on PrismicLanguageDonationsPageBodyLanguageProjects {
                id
                primary {
                  languageName: language_name {
                    text
                  }
                  flagCode: flag_code {
                    text
                  }
                  continent
                }
              }
            }
          }
        }
      }
    }
    donationsDetails: allPrismicDonationsPage {
      edges {
        node {
          data {
            headingDescriptions: heading_descriptions {
              text
            }
            backgroundImage: background_image {
              alt
              url
              fluid(maxWidth: 1280) {
                ...GatsbyPrismicImageFluid
              }
            }
            projects {
              title {
                text
              }
              thumbnail {
                url
              }
              goal
              shortDescriptions: short_descriptions {
                text
              }
              featured
              detailsPage: details_page {
                id
                slug
                uid
                document {
                  __typename
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default RecurringDonation;
