import React from 'react';

import Modal from '../Modal';
import DeleteIcon from '../../images/donation/delete_box.svg';

const RemoveRecurringDonationModal = ({ open, onClose, onConfirmDelete, projectName, isLoading }) => {
  const handleDelete = () => {
    onConfirmDelete();
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose} sx={{ maxWidth: '900px' }}>
      <div className="project-deletion-modal">
        <div className="project-deletion-modal-container">
          <div className="donation-project-img">
            <img src={DeleteIcon} />
          </div>
          <h2 className="donation-project-msg">
            Are you sure you want to cancel the recurring monthly donation for Lineage in {projectName}?
          </h2>
          <div className="donation-modal-body">
            <p className="donation-project-descriptions">
              We have received your request to cancel your donation to Lineage. We want to express our gratitude for
              your past support. Please understand that this may affect our project's progress. Our support team is
              ready to assist you with any questions or concerns.
            </p>

            <div className="btn-group donation-project-button">
              <button className={`btn btn-md btn-outline ${isLoading ? 'btn-loading' : ''}`} onClick={onClose}>
                {isLoading ? <span className="spinner"></span> : 'Cancel'}
              </button>
              <button className={`btn btn-md btn-primary-ii ${isLoading ? 'btn-loading' : ''}`} onClick={handleDelete}>
                {isLoading ? <span className="spinner"></span> : 'Yes'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default RemoveRecurringDonationModal;
